import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as contactstaffeTOll1r1zAMeta } from "/opt/buildhome/repo/pages/contactstaff.vue?macro=true";
import { default as home4pMUrBFKiHMeta } from "/opt/buildhome/repo/pages/home.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as luckydraw0n8LRfjZy5Meta } from "/opt/buildhome/repo/pages/luckydraw.vue?macro=true";
import { default as mybonusFoiwm7k2x0Meta } from "/opt/buildhome/repo/pages/mybonus.vue?macro=true";
import { default as editFsLKGmHb9bMeta } from "/opt/buildhome/repo/pages/torrent/[id]/edit.vue?macro=true";
import { default as indexTMYaMuiAVqMeta } from "/opt/buildhome/repo/pages/torrent/[id]/index.vue?macro=true";
import { default as indexlM2L68QICbMeta } from "/opt/buildhome/repo/pages/torrent/index.vue?macro=true";
import { default as uploadprbDJhZi8jMeta } from "/opt/buildhome/repo/pages/upload.vue?macro=true";
import { default as usercpyzGh4xUAnDMeta } from "/opt/buildhome/repo/pages/usercp.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "contactstaff",
    path: "/contactstaff",
    component: () => import("/opt/buildhome/repo/pages/contactstaff.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/opt/buildhome/repo/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "luckydraw",
    path: "/luckydraw",
    component: () => import("/opt/buildhome/repo/pages/luckydraw.vue")
  },
  {
    name: "mybonus",
    path: "/mybonus",
    component: () => import("/opt/buildhome/repo/pages/mybonus.vue")
  },
  {
    name: "torrent-id-edit",
    path: "/torrent/:id()/edit",
    component: () => import("/opt/buildhome/repo/pages/torrent/[id]/edit.vue")
  },
  {
    name: "torrent-id",
    path: "/torrent/:id()",
    component: () => import("/opt/buildhome/repo/pages/torrent/[id]/index.vue")
  },
  {
    name: "torrent",
    path: "/torrent",
    meta: indexlM2L68QICbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/torrent/index.vue")
  },
  {
    name: "upload",
    path: "/upload",
    component: () => import("/opt/buildhome/repo/pages/upload.vue")
  },
  {
    name: "usercp",
    path: "/usercp",
    component: () => import("/opt/buildhome/repo/pages/usercp.vue")
  }
]